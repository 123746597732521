.main-loader {
    margin: auto;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #8fb131;
    border-right: 8px solid #8fb131;
    border-bottom: 8px solid #8fb131;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
