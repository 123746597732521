.EZDrawer .EZDrawer__overlay {
    height: 100%;
    overflow: hidden;
}

.ruleContainer {
    z-index: 101;
    transition-duration: 500ms;
    right: 0px;
    top: initial !important;
    transform: translate3d(100%, 0px, 0px);
    width: 400px;
    height: calc(100vh - 140px) !important;
    margin: 20px;
    border-radius: 16px;
}

input:hover {
    border: 2px solid #9ed79f !important;
    background-color: #ffffff;
}

input:disabled {
    border: 2px solid #f1f1f1;
    background-color: #f1f1f1;
}
input {
    border: 2px solid #f1f1f1 !important;
    background-color: #f1f1f1;
}

input:focus {
    border: 2px solid #9ed79f !important;
    background-color: #ffffff;
}
input:focus-visible {
    outline: #9ed79f;
}

.react-flow-subflows-example .react-flow__node:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    background: #1a1a1a;
    border: 1px solid #1a1a1a;
    border-radius: 4px;
}

.react-flow-subflows-example .react-flow__node:first-child:hover {
    background: #1a1a1a;
    border: 1px solid #1a1a1a !important;
}

.react-flow-subflows-example .react-flow__node:first-child:focus {
    background: #1a1a1a;
    border: 1px solid #1a1a1a !important;
}

.react-flow-start {
    top: 30% !important;
}
