.form-select {
    padding: 0.3125rem 3rem 0.3125rem 1rem;
    background-image: url('assets/images/arrowdown.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 1rem center;
    border-radius: 0.25rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #d8e2ef;
    color: #344040;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    outline: none;
}

option:disabled {
    background-color: #f1f1f1 !important;
    color: #b1b1b1 !important;
}
