.custom-multiselect .searchBox:hover {
    border: none !important;
}

.custom-multiselect .searchBox:focus {
    border: none !important;
}

.custom-multiselect .searchBox {
    border: none !important;
}
