.toggle-switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 15px;
}
.toggle-switch input[type='checkbox'] {
    display: none;
}

.toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
}

.toggle-switch .switch::before {
    position: absolute;
    content: '';
    left: 2px;
    top: -3px;
    width: 20px;
    height: 20px;
    background-color: #c8c8c8;
    border-radius: 50%;
    transition: transform 0.3s ease;
}
.toggle-switch input[type='checkbox']:checked + .switch::before {
    transform: translateX(22px);
    background-color: #8fb131;
}

input[type='checkbox']:checked + .active-switch::before {
    background-color: #2e672f !important;
}

.toggle-switch-rule .switch-rule::before {
    width: 16px;
    height: 16px;
    left: 0;
    background-color: #ffffff;
}
.toggle-switch-rule {
    width: 32px;
    height: 10px;
}

.toggle-switch-side-menu .switch::before {
    background-color: #2e672f;
    left: -5px;
    top: -5px;
}
.toggle-switch-side-menu {
    width: 32px;
    height: 10px;
}
