.tooltip-container {
    position: relative;
    display: inline-block;
    z-index: 49;
}

.tooltip-text {
    width: 100%;
    visibility: hidden;
    font-size: 12px;
    color: #333;
    background-color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    margin-top: 5px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
