.thHeader {
    background-color: #f5f5f5;
    padding: 12px;
    font-weight: 500;
    text-align: center;
    color: #131119;
    white-space: nowrap;
    word-wrap: break-word;
    font-family: 'Poppins-bold', sans-serif;
    letter-spacing: -0.1px;
}
.thHeader:last-child {
    border-top-right-radius: 12px;
}
.thHeader:first-child {
    border-top-left-radius: 12px;
}

.tableSection {
    border-collapse: collapse;
    border: none;
    background: #ffffff;
    padding: 10px;
}

.tableCell {
    cursor: auto;
    color: #383838;
}

.tableCell:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.tableRow {
    padding: 12px;
    font-size: 12px;
    color: #383838;
    box-sizing: border-box;
    height: 41px;
    font-weight: 400;
    font-family: 'Poppins-regular', sans-serif;
    letter-spacing: -0.1px;
}
