.content {
    height: calc(100vh - 95px);
    width: calc(100vw - 256px);
}
@media only screen and (max-height: 800px) {
    .content {
        overflow: auto;
    }
}

.header {
    width: calc(100vw - 320px);
}
