.default-switch[type='checkbox'] + .switch::before {
    background-color: #2e672f;
    position: absolute;
    left: 0;
    top: -2.5px;
}

.default-switch[type='checkbox']:checked + .switch::before {
    background-color: #8fb131;
    position: absolute;
    left: 3px;
    top: -2.5px;
}

.default-switch[type='checkbox']:checked + .switch {
    background-color: #DEECAA;
    width: 40px;
}
.default-switch[type='checkbox'] + .switch {
    background-color: #E3E3E3;
    width: 40px;
}
.default-switch[type='checkbox']:disabled + .switch::before {
    background-color: #E3E3E3;
    position: absolute;
    left: -2px;
    top: -2.5px;
    cursor: not-allowed;
}
.default-switch[type='checkbox']:disabled + .switch {
    background-color: #b4b4b3;
    width: 40px;
    cursor: not-allowed;
}