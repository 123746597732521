.clipboard-input {
    background-color: transparent !important;
    border: none !important;
}
.clipboard-input:hover {
    border: none !important;
}
.clipboard-input:focus {
    border: none !important;
}
