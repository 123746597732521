.searchList {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    flex-direction: column;
}

.menuList {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 2px solid #ffffff;
    border-radius: 16px;
    cursor: pointer;
    background: #f8fbea;
    gap: 10px;
}

.menuList img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.menuList.selected {
    border: 2px solid #8fb131;
    background: #f8fbea;
}
.menuList.disabled {
    border: 1px solid #ccc;
}

.menuList:hover {
    border: 2px solid #8fb131;
}

.menuList.disabled:hover {
    border: 1px solid #ccc;
    cursor: not-allowed;
}

.searchable-payment-providers-list {
    position: relative;
}

.searchable-payment-providers-list input[type='search'] {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    box-sizing: border-box;
    gap: 4px;
    height: 48px;
}

.searchable-payment-providers-list .searchList {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    border-top: none;
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;
}
