.shadow-fade-top {
    background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
    );
}

.bullet-list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
}

.bullet-list div {
    position: relative;
    padding-left: 20px;
}

.bullet-list div::before {
    content: '•'; /* Unicode character for the bullet */
    position: absolute;
    left: 0;
    top: 3%;
    transform: translateY(-3%); /* Vertically centers the bullet */
    font-size: 16px; /* Adjust bullet size if needed */
    color: black; /* Bullet color */
}
@font-face {
    font-family: 'Poppins-bold';
    src: url(assets/fonts/Poppins-Bold.ttf) format('opentype');
}
.title {
    font-weight: bold;
    padding: 20px 0;
    font-family: 'Poppins-bold', sans-serif !important;
}
