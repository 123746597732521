.EZDrawer .EZDrawer__overlay {
    height: 100%;
    overflow: hidden;
}
@media only screen and (max-height: 670px) {
    .transactionModal {
        max-height: 500px !important;
        overflow: scroll;
    }
}
@media only screen and (max-height: 900px) {
    .approvalmodal {
        max-height: 700px !important;
        overflow: scroll;
    }
}
@media only screen and (max-height: 730px) {
    .approvalmodal {
        max-height: 500px !important;
        overflow: scroll;
    }
}
