.pagination-container {
    display: flex;
    list-style-type: none;
}
.pagination-item {
    padding: 0 14px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 10px;
    line-height: 1.43;
    font-size: 13px;
}
.pagination-item > .dots:hover {
    background-color: transparent;
    cursor: default;
}
.pagination-item:hover {
    cursor: pointer;
}

.arrow::before {
    position: relative;
    /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.6em;
    height: 0.6em;
    border-right: 2px solid rgba(0, 0, 0, 0.43);
    border-top: 2px solid rgba(0, 0, 0, 0.43);
}

.arrow:hover {
    background-color: transparent;
    cursor: pointer;
}
