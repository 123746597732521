.simple-floatingedges {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: calc(100vh - 94px);
}

.simple-floatingedges .react-flow__handle {
    width: 20px;
    height: 20px;
    background-color: #bbb;
}

.simple-floatingedges .react-flow__handle-top {
    display: flex;
    position: fixed;
    top: -24px;
    left: 49%;
}
.simple-floatingedges .react-flow__handle-top svg {
    display: flex;
    position: absolute;
    width: 20px;
    height: 20px;
}

.simple-floatingedges .react-flow__handle-bottom {
    display: flex;
    position: fixed;
    bottom: -24px;
    left: 49%;
}
.simple-floatingedges .react-flow__handle-bottom svg {
    display: flex;
    position: absolute;
    width: 20px;
    height: 20px;
}

.simple-floatingedges .react-flow__handle-left {
    display: flex;
    position: fixed;
    left: -24px;
    top: 18px;
}
.simple-floatingedges .react-flow__handle-left svg {
    display: flex;
    position: absolute;
    width: 20px;
    height: 20px;
}

.simple-floatingedges .react-flow__handle-right {
    display: flex;
    position: fixed;
    right: -24px;
    top: 22px;
}
.simple-floatingedges .react-flow__handle-right svg {
    display: flex;
    position: absolute;
    width: 20px;
    height: 20px;
}

.simple-floatingedges .react-flow__node-custom {
    background: #fff;
    border-radius: 3px;
    color: #222;
    font-size: 12px;
    text-align: center;
    width: 220px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.simple-floatingedges .react-flow__node-custom > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-flow-subflows-example .react-flow__node:first-child {
    height: 40px;
}
.react-flow__handle > div > i svg rect {
    stroke: #8fb131;
}
.react-flow__handle > div > i svg path {
    stroke: #8fb131;
}
