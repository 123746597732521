.searchWrapper {
    border-radius: 8px !important;
    background: white;
    width: 100%;
    min-height: 50px;
    border: solid #f5f5f5 2px !important;
    padding: 0 10px;
}
.searchWrapper:hover {
    border: solid #9ed79f 2px !important;
}
.highlightOption {
    background: white !important;
    color: black !important;
}
.optionContainer {
    border-radius: 8px;
    border: 1px solid #8fb131 !important;
}
.custom-close {
    margin: 2px 0 0 4px;
    cursor: pointer;
}
.option:hover {
    background: #8fb131 !important;
}
.option {
    font-size: 14px;
}
.icon_down_dir:before {
    content: '' !important;
}
.icon_down_dir {
    margin: 5px 20px 0 0;
}
.multiSelectContainer input:hover {
    background-color: transparent;
}
.multiSelectContainer input:focus {
    background-color: transparent;
}
