.flatpickr-calendar .flatpickr-months .flatpickr-month {
    --tw-bg-opacity: 1;
    background-color: rgb(
        46 103 47 / var(--tw-bg-opacity)
    ); /* Set the desired background color */
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    --tw-text-opacity: 1;
    background-color: rgb(143 177 49 / var(--tw-text-opacity));
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    --tw-bg-opacity: 1;
    background-color: rgb(46 103 47 / var(--tw-bg-opacity));
}

.flatpickr-current-month
    .flatpickr-monthDropdown-months
    .flatpickr-monthDropdown-month {
    --tw-bg-opacity: 1;
    background-color: rgb(46 103 47 / var(--tw-bg-opacity));
}

.flatpickr-current-month
    .flatpickr-monthDropdown-months
    .flatpickr-monthDropdown-month:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(143 177 49 / var(--tw-text-opacity)) !important;
}
.flatpickr-current-month {
    font-size: 100%;
}

.flatpickr-weekdays {
    --tw-bg-opacity: 1;
    background-color: rgb(46 103 47 / var(--tw-bg-opacity));
    color: #ffffff;
}

.flatpickr-weekdaycontainer .flatpickr-weekday {
    --tw-bg-opacity: 1;
    background: rgb(46 103 47 / var(--tw-bg-opacity));
    color: #ffffff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    --tw-bg-opacity: 1;
    background-color: rgb(46 103 47 / var(--tw-bg-opacity));
    border-color: #2e672f;
}

.flatpickr-day:hover {
    background-color: rgb(143 177 49) !important;
    border-color: rgb(143 177 49) !important;
}

.flatpickr-day.today {
    border-color: #2e672f;
}

.flatpickr-calendar.arrowTop:before {
    border-bottom-color: #2e672f;
}

.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #2e672f;
}
