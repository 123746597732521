.Dropdown {
    border-radius: 4px;
    border: 1px solid #e3e3e3;
    background: #e3e3e3;
    box-shadow: 0 8px 24px 0 rgba(11, 15, 44, 0.12);
    z-index: 10000000;
    display: flex;
    width: 148px;
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
    margin-top: 5px;
}

.menu-items {
    display: flex;
    height: 32px;
    padding: 4px 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    background-color: #fafaf5 !important;
    border-radius: 4px !important;
}
.menu-items:hover {
    background: #deecaa !important;
}

.sub-menu {
    color: #131119;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: -0.08px;
    font-family: Poppins-regular, serif;
}
