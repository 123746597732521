@import url('https://fonts.googleapis.com/css?family=Overpass');

.tags-input {
    border: 2px solid #f1f1f1;
    background-color: #f1f1f1;
}
.tags-input:hover {
    border: 2px solid #9ed79f;
    background-color: #ffffff;
}

.tags-input:focus {
    border: 2px solid #9ed79f;
    background-color: #ffffff;
}

.tags-input:focus-visible {
    outline: #9ed79f;
}

.tags-input input {
    flex: 1;
    border: none !important;
}
.tags-input input:hover {
    border: none !important;
}
.tags-input input:focus {
    border: none !important;
    background-color: transparent;
}
#tags {
    flex-wrap: wrap;
    padding: 0;
}

.tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
}

.tag .tag-title {
    margin-top: 3px;
}

.tag .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #0052cc;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}

@media screen and (max-width: 567px) {
    .tags-input {
        width: calc(100vw - 32px);
    }
}
